import { Fragment, useState } from 'react';
import Feedback from '../feedback';
import Modal from 'react-modal';
import { Dialog, Transition } from '@headlessui/react';
import VgaCertificate from './layout/VgaCertificate';
import { partnerIds } from 'config/common';
import VgaPartnerCertificate from './layout/VgaPartnerCertificate';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import VgaCompleteCertificate from './layout/VgaCompleteCertificate';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const DownloadCertificate = ({
  batchData,
  feedbackGiven,
  batchId,
  isAllTaskDone,
}: {
  batchData: any;
  feedbackGiven: boolean;
  batchId: string;
  isAllTaskDone: boolean;
}) => {
  const { basicDetails } = useBasicDetailsContext();
  const isFullJourney = basicDetails?.isFullJourney;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isCertificateOpen, setIsCertificateOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  const isDeviareBatch = batchData?.partner === partnerIds.deviare;

  const holdCertificateUser = [
    'rakgabalet@gmail.com',
    'nkosizmorgan@hotmail.com',
  ];

  const holdCertificate = holdCertificateUser.includes(basicDetails?.useremail);
  return (
    <div className='md:flex justify-between space-y-4 md:space-y-0'>
      <div className='space-y-4 md:w-1/2'>
        <p className='font-semibold text-lg'>VGA Feedback and Certificate</p>
        <p className='font-bold'>
          Congratulations, you have completed the Virtual Global Apprenticeship.
          <br />
          <p className='font-bold'>
            Please fill the feedback form to access the Certificate
          </p>
        </p>
      </div>
      <div className='space-y-4'>
        <button
          onClick={() => {
            if (feedbackGiven) {
              setIsCertificateOpen(true);
            } else {
              openModal();
            }
          }}
          className='bg-primary px-4 py-2 cursor-pointer text-white font-semibold rounded-lg'>
          Get Certificate
        </button>
        {/* <p className='text-sm text-primary font-medium cursor-pointer'>
          Share with your network
        </p> */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Feedback Modal'
        data={{ background: 'gray' }}>
        <div>
          <Feedback batchId={batchId} />
        </div>
      </Modal>

      <Transition appear show={isCertificateOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => {
            setIsCertificateOpen(false);
          }}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='mx-auto rounded bg-white'>
                  {batchId === '646b374d74b3be00148676ea' ? (
                    <div className='m-6 font-semibold h-64 w-96 my-auto mt-32 text-xl '>
                      To access your certificate, please email us at
                      connect@belong.education
                      <div className='mt-24 flex justify-end'>
                        <button
                          onClick={() => {
                            setIsCertificateOpen(false);
                          }}
                          className='bg-primary px-4 py-2 cursor-pointer text-white font-semibold rounded-lg'>
                          Close
                        </button>
                      </div>
                    </div>
                  ) : holdCertificate ? (
                    <div className='m-6 font-semibold h-64 w-96 my-auto mt-32 text-xl '>
                      Your certificate is currently on hold. Please complete
                      your assignment to gain access to it.
                      <div className='mt-24 flex justify-end'>
                        <button
                          onClick={() => {
                            setIsCertificateOpen(false);
                          }}
                          className='bg-primary px-4 py-2 cursor-pointer text-white font-semibold rounded-lg'>
                          Close
                        </button>
                      </div>
                    </div>
                  ) : //Certificate for Deviare
                  isFullJourney ? (
                    <VgaCompleteCertificate
                      batchData={batchData}
                      isAllTaskDone={isAllTaskDone}
                    />
                  ) : isDeviareBatch ? (
                    <VgaCertificate
                      batchData={batchData}
                      isAllTaskDone={isAllTaskDone}
                    />
                  ) : (
                    //Certificate for other partners
                    <VgaPartnerCertificate
                      batchData={batchData}
                      isAllTaskDone={isAllTaskDone}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default DownloadCertificate;
