import { useNavigate } from 'react-router-dom';
import { useGetCapstoneInfoQuery } from './panelSlice';
import { partnerIds } from 'config/common';
// const progressdata: any = [
//   {
//     id: 1,
//     title: 'Capstones',
//     progress: '1/1',
//     left: 'Ongoing',
//     leftNumber: '1',
//     right: 'Completed',
//     rightNumber: '2',
//     imgLink:
//       'https://res.cloudinary.com/belong/image/upload/v1668161240/new%20b2b/image_238_zsbi01.png',
//   },
//   {
//     id: 2,
//     title: 'Apprenticeship',
//     progress: 'Upcoming',
//     left: 'Events',
//     leftNumber: 'NA',
//     right: 'Tasks',
//     rightNumber: 'NA',
//     imgLink:
//       'https://res.cloudinary.com/belong/image/upload/v1668161239/new%20b2b/image_241_uyqbuz.png',
//   },
// ];
/* <div
        key={data.id}
        className='relative  mt-4 md:mx-2  pb-4 text-center border rounded bg-primary-lightest'
      >
        <div className='absolute text-xs bg-primary rounded text-white px-4 py-0.5  text-center right-0 top-0'>
          {data.progress}
        </div>
        <div className='flex justify-center pt-4 mt-4'>
          <img src={data.imgLink} alt='logo' />
        </div>
        <p className='my-2 font-semibold'>{data.title}</p>
        <div className='flex justify-around font-semibold text-sm'>
          <div className='space-y-2'>
            <p>{data.left}</p>
            <p className='text-primary'>{data.leftNumber}</p>
          </div>
          <div className='border-l-2'></div>
          <div className='space-y-2 '>
            <p>{data.right}</p>
            <p className='text-primary'>{data.rightNumber}</p>
          </div>
        </div>
      </div> */

const ProgressCard = ({ userData, onbordingData }: any) => {
  const isTapmi = userData?.partnerId?._id === partnerIds.tapmi;
  const domainScore = onbordingData?.domain?.percentage;
  const { data } = useGetCapstoneInfoQuery();
  const navigate = useNavigate();
  const testGiven = onbordingData?.domain?.totalAttempts > 0;
  const domainTestEnabled =
    userData?.partnerId?.sidebarTab?.capstone && !isTapmi;
  const isNirmanUser = userData?.partnerId?._id === partnerIds.nirman;
  //Show domain card only if capstone is enabled for a partner or a domain test has already been given
  const showDomainCard = testGiven || domainTestEnabled || isNirmanUser;

  return (
    <>
      {showDomainCard && (
        <div className='relative  mt-4 md:mx-2  pb-4 text-center border rounded bg-primary-lightest'>
          <div className='absolute text-xs bg-primary rounded text-white px-4 py-0.5  text-center right-0 top-0'>
            1/1
          </div>
          <div className='flex justify-center pt-4 mt-4'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1667818455/new%20b2b/fluent_brain-circuit-20-regular_qezubn.png'
              alt='brain'
            />
          </div>
          <p className='my-2 font-semibold'>Domain</p>
          <p className='font-semibold text-sm'>Quiz Performance</p>
          {testGiven && (
            <p className='text-primary font-bold text-sm mt-1'>
              {domainScore} %
            </p>
          )}
          <button
            className='text-xs  mt-1 text-white bg-primary rounded-2xl  px-3 py-1'
            onClick={() => {
              if (testGiven) {
                navigate('/quiz/domain/scorecard');
              } else {
                navigate('/quiz/domain/steps/select-domain');
              }
            }}>
            {testGiven ? 'Score Card' : 'Start Test'}
          </button>
        </div>
      )}

      {data?.total > 0 && !isNirmanUser && (
        <div className='relative  mt-4 md:mx-2  pb-4 text-center border rounded bg-primary-lightest'>
          <div className='absolute text-xs bg-primary rounded text-white px-4 py-0.5  text-center right-0 top-0'>
            {data?.completed}/{data?.total}
          </div>
          <div className='flex justify-center pt-4 mt-4'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1668161240/new%20b2b/image_238_zsbi01.png'
              alt='logo'
            />
          </div>
          <p className='my-2 font-semibold'>Capstones</p>
          <div className='flex justify-around font-semibold text-sm'>
            <div className='space-y-2'>
              <p>Ongoing</p>
              <p className='text-primary'>{data?.total - data?.completed}</p>
            </div>
            <div className='border-l-2'></div>
            <div className='space-y-2 '>
              <p>Completed</p>
              <p className='text-primary'>{data?.completed}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressCard;
