import React, { useEffect, useRef, useState } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
// import { format } from 'date-fns';
import classNames from 'classnames';
import { format } from 'date-fns';

const VgaPartnerCertificate = ({
  batchData,
  isAllTaskDone,
}: {
  batchData: any;
  isAllTaskDone: boolean;
}) => {
  const userDetails = batchData.users[0];

  const completionStatus =
    userDetails.completionStatus === 'Not Applicable' && isAllTaskDone
      ? 'Completion'
      : userDetails.completionStatus;
  const companyName = userDetails.company.name;
  const companyLogo = userDetails.company.logoUrl;
  const domainName = userDetails.area.title;
  console.log(completionStatus);
  const durationText = `${format(
    new Date(batchData.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchData.endDate), 'do LLLL y')}`;
  const pdfExportComponent = useRef<any>(null);
  const contentArea = useRef(null);
  const [toggle, setToggle] = useState(true);

  const handleExportWithMethod = () => {
    setToggle(false);
  };

  useEffect(() => {
    if (!toggle) {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save(() => {
          setToggle(true);
        });
      }
    }
  }, [toggle]);

  const certificateUrl = userDetails.certificateUrl || batchData.logoUrl;

  return (
    <>
      {completionStatus === 'Not Applicable' ? (
        <div className='m-6'>
          <p className='text-primary text-2xl font-bold mb-4 mt-4 text-center'>
            Thank you for submitting your feedback.
          </p>
          <div className='text-center mb-6'>
            Please submit all the company project tasks and assignments to
            access your VGA Certificate.
          </div>
        </div>
      ) : (
        <>
          <PDFExport paperSize='auto' landscape={true} ref={pdfExportComponent}>
            <div
              ref={contentArea}
              className='w-[1200px] h-[800px] mx-auto my-8 bg-[url("https://res.cloudinary.com/belong/image/upload/v1690269095/uploaded_resources/WhatsApp_Image_2023-07-25_at_12.36.03_bngscu.jpg")] bg-[length:1180px_650px] bg-center bg-no-repeat'>
              <div className='grid grid-cols-12'>
                <div className='col-span-8 '>
                  <div className='px-20 text-left'>
                    <img
                      src={certificateUrl}
                      alt='logo'
                      className='h-20 mt-28 object-contain'
                    />
                    <img
                      src={companyLogo}
                      alt='logo'
                      className='h-16 mt-10 object-contain'
                    />
                    <div className='mt-10'>
                      This Certificate is presented to
                    </div>

                    <div>
                      <p className='text-4xl font-semibold font-abhaya1 my-6'>
                        {userDetails.name}
                      </p>

                      {toggle ? (
                        completionStatus === 'Completion' ? (
                          <>
                            <p className='text-neutral-900  '>
                              for successful completion of Virtual Global
                              Apprenticeship in{' '}
                              <span className='font-bold'>{domainName}</span>.
                              This achievement stands as evidence of his/her
                              valuable input to the
                              <span> {'  '}</span>
                              <span className='font-bold'>{companyName}</span>
                              <span> {'  '}</span>
                              project, for the duration{' '}
                              <span className='font-bold'>{durationText}</span>
                            </p>
                            <p className='text-2xl text-primary font-semibold font-abhaya1 mt-6'>
                              Congratulations on this Achievement !
                            </p>
                          </>
                        ) : (
                          <>
                            <p className='text-neutral-900'>
                              in recognition of his/her outstanding performance
                              and commitment during Virtual Global
                              Apprenticeship in{' '}
                              <span className='font-bold'>{domainName}</span> .
                              This achievement stands as evidence of his/her
                              valuable input to the
                              <span> {'  '}</span>
                              <span className='font-bold'>
                                {companyName}
                              </span>{' '}
                              <span> {'  '}</span>
                              project, for the duration{' '}
                              <span className='font-bold'>{durationText}</span>
                            </p>
                            <p className='text-2xl text-primary font-semibold font-abhaya1 mt-6'>
                              Congratulations on this Achievement !
                            </p>
                          </>
                        )
                      ) : completionStatus === 'Completion' ? (
                        <>
                          <p className='text-neutral-900'>
                            for successful completion of Virtual Global
                            Apprenticeship in{' '}
                            <span className='mr-2 font-bold'>{domainName}</span>
                            . This achievement stands as evidence of his/her
                            valuable input to the
                            <span> {'  '}</span>
                            <span className='mr-2 font-bold'>
                              {companyName}
                            </span>{' '}
                            <span> {'  '}</span>
                            project, for the duration{' '}
                            <span className='mr-2 font-bold'>
                              {durationText}
                            </span>
                          </p>
                          <p className='text-2xl text-primary font-semibold font-abhaya1 mt-6'>
                            Congratulations on this Achievement !
                          </p>
                        </>
                      ) : (
                        <>
                          <p className='text-neutral-900 '>
                            in recognition of his/her outstanding performance
                            and commitment during Virtual Global Apprenticeship
                            in
                            <span className='mr-2 font-bold'>
                              {domainName}
                            </span>{' '}
                            . This achievement stands as evidence of his/her
                            valuable input to the
                            <span> {'  '}</span>
                            <span className='mr-2 font-bold'>
                              {companyName}
                            </span>
                            <span> {'  '}</span>
                            project, for the duration
                            <span className='mr-2 font-bold'>
                              {durationText}
                            </span>
                          </p>
                          <p className='text-2xl text-primary font-semibold font-abhaya1 mt-6'>
                            Congratulations on this Achievement !
                          </p>
                        </>
                      )}
                    </div>
                    <div className='flex space-x-20'>
                      <div className='space-y-1'>
                        <img
                          src={
                            userDetails.coach.coachSign ||
                            userDetails.mentor.mentorSign
                          }
                          alt='Logo'
                          className='h-12 my-2.5 -ml-4 object-contain'
                        />
                        <hr />

                        <p className='text-[#767676] '> INDUSTRY MENTOR</p>
                      </div>
                      <div className='space-y-1'>
                        <div className='flex'>
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                            alt='Logo'
                            className='h-16 object-contain'
                          />
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                            alt='Logo'
                            className='h-16 object-contain'
                          />
                        </div>
                        <hr />
                        <p className='text-[#767676] '>Belong Co - Founders</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className='col-span-4'>
                  <div className='w-[60%]  ml-20 mb-6 mt-24'>
                    {completionStatus === 'Completion' ? (
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1690269860/uploaded_resources/Frame_904_lmc8ws.png'
                        alt='banner'
                      />
                    ) : (
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1690269731/uploaded_resources/Frame_710_l9bbsx.png'
                        alt='banner'
                      />
                    )}
                  </div>
                  <div className='space-y-2 text-right text-sm mr-20'>
                    <p
                      className={classNames('text-primary font-semibold', {
                        '-mr-2': !toggle,
                      })}>
                      Virtual Global Apprenticeship
                    </p>
                    <p
                      className={classNames('text-[#767676] font-medium', {
                        '-mr-4': !toggle,
                      })}>
                      Certificate associated with Belong.education
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
          <div className='flex flex-col items-center p-2 border-2 border-b-0 bg-white'>
            <button
              onClick={handleExportWithMethod}
              className='visible rounded mt-3 text-gray-100 text-sm px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'>
              Download Certificate
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default VgaPartnerCertificate;
